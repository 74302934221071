import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { carousels } from 'assets/images/carousels';
import "./CCarousel.css";
import { useEffect } from 'react';

const items = [
  {
    src: carousels.carousel1,
    altText: 'Brand',
    captionHeader: 'Vadi Kağızman',
    captionText: 'Ağız ve Diş Sağlığı Polikliniği',
    key: 1,
  },
  {
    src: carousels.carousel2,
    altText: 'Diş Sağlığınız İçin Hizmetinizdeyiz',
    captionHeader: 'Diş Sağlığınız İçin Hizmetinizdeyiz',
    captionText: '7 & 24',
    key: 2,
  },
  {
    src: carousels.carousel3,
    altText: 'Uzun Ömürlü, Güvenilir, Estetik',
    captionHeader: 'İMPLANT',
    captionText: 'Uzun Ömürlü, Güvenilir, Estetik',
    key: 3,
  },
  {
    src: carousels.carousel4,
    altText: 'Çocuk Alanı',
    captionHeader: 'Çocuk Oyun Alanı',
    captionText: 'Çocuklarınız Neşe Kaynağımız',
    key: 4,
  }
];

function CCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const carouselCaptions = document.querySelectorAll(".carousel-caption");
    carouselCaptions.forEach(carouselCaption => {
        carouselCaption.classList.remove("d-none");
    });
  }, []);

  const shuffle = (array) => {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }

  const snowflakes = () => {
    const snowflakeArray = [];
    const snowflakeCount = 10;
    const fallAnimationDuration = 5;
    const delays = [...Array(snowflakeCount).keys()];
    shuffle(delays);

    for (let index = 0; index < snowflakeCount; index++) {
        const randomShakeAnimationDelay = Math.floor(Math.random() * 3);
        snowflakeArray.push(
            <span key={`snowflake_${index}`} className='snowflake' style={{
                "--i" : index,
                "--fallDelay" : delays[index] + 's',
                "--shakeDelay" : randomShakeAnimationDelay + 's',
                "--fallAnimationDuration" : fallAnimationDuration + 's'
                }}>
                &#10054;
            </span>
        )
    }
    return snowflakeArray;
  }

  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        {
            index === 0 ? 
            <div>
                <div className='snowflakes'>
                    {snowflakes()}
                </div>
                <img src={item.src} alt={item.altText}/>
            </div> : 
            <img src={item.src} alt={item.altText}/>
        }
        
        <CarouselCaption
          captionText={item.captionText}
          captionHeader={item.captionHeader}
        />
      </CarouselItem>
    );
  });

  

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      fade={true}
      interval={10000}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Geri"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="İleri"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default CCarousel;