import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import "./BottomBar.css";

const BottomBar = () => {
    return (
        <div id="bottombar">
            <div id="bottombar-left">
                <div className="px-4">
                    Copyright <FontAwesomeIcon icon={faCopyright} /> 2024, &nbsp;
                    <h1 className="m-0">
                        Vadi Kağızman Ağız ve Diş Sağlığı Polikliniği
                    </h1>
                </div>
            </div>
            <div id="bottombar-right">
                <span><a href="https://osmandurdag.com" target='_blank' rel='noreferrer'>Osman DURDAĞ</a> tarafından geliştirilmiştir</span>
            </div>
        </div>
    )
}

export default BottomBar;