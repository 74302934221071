import CCarousel from "./components/Carousel/CCarousel";
import NNavbar from "./components/Navbar/NNavbar";
import Topbar from "./components/TopBar/Topbar";
import About from "./components/About/About";
import Treatments from "./components/Treatments/Treatments";
import Dentists from "./components/Dentists/Dentists";
// import Appointment from "./components/Appointment/Appointment";
import Footer from "./components/Footer/Footer";
import GoogleMap from "./components/GoogleMap/GoogleMap";
import Sidebar from "./components/Sidebar/Sidebar";


function App() {

    const debug = false;

    if (!debug){
        document.addEventListener("contextmenu", event => {
            event.preventDefault();
        });
        document.addEventListener("keydown", event => {
            if(event.key === "F12"){
                event.preventDefault();
            }
            else if(event.ctrlKey && event.shiftKey && event.code === "KeyI"){
                event.preventDefault();
            }
        });
    }

	return (
		<>
			<Topbar/>
			<NNavbar/>
			<CCarousel/>
			<About/>
			<Treatments/>
			<Dentists/>
			{/* <Appointment/> */}
			<GoogleMap/>
			<Footer/>
            <Sidebar/>
		</>
	);
}

export default App;