import React from 'react';
import "./About.css";
import { images } from 'assets/images';
import { ReactComponent as BadgeIcon } from 'assets/icons/badge.svg';

const About = () => {
    return (
        <section id="aboutContainer">
            <div id='about'>
                <div className="aboutLeft">
                    <img src={images.about} alt="About" />
                </div>
                <div className="aboutRight">
                    <h4>Hakkımızda</h4>
                    <h1>Kağızman'ın En İyi Özel Ağız ve Diş Sağlığı Polikliniği</h1>
                    <p>
                        2024 yılı Temmuz ayında Kars'ın Kağızman ilçesinde hizmet vermeye başlayan polikliniğimiz, uzman diş hekimlerimizin güleryüzlü ve özverili disipliniyle sizlere en kaliteli hizmeti sunmaktadır. İmplant tedavileri, ortodonti tedavileri, endodonti tedavileri, çocuklara ve yetişkinlere yönelik tedaviler gibi birçok diş hekimliği uygulaması tecrübeli ve alanında uzman hekimlerimiz tarafından polikliniğimiz bünyesinde gerçekleştirilmektedir.
                    </p>
                    <div className='badges'>
                        <div className="badgesLeft">
                            <p>
                                <BadgeIcon className='svgIcon' /> Profesyonel Kadro
                            </p>
                            <p>
                                <BadgeIcon className='svgIcon' /> 7 / 24 Hizmetinizde
                            </p>
                        </div>
                        <div className="badgesRight">
                            <p>
                                <BadgeIcon className='svgIcon' /> Uygun Fiyatlar
                            </p>
                            <p>
                                <BadgeIcon className='svgIcon' /> Mutlu Hastalar
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;